import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export class AuthInput {
  email: Scalars['String'];
  password: Scalars['String'];
};

export class AuthType {
  token: Scalars['String'];
  user: User;
};

export class Bank {
  bankName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  icon: Scalars['String'];
  id: Scalars['ID'];
  maskAccount: Scalars['String'];
  maskAgency: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userInfoAccount?: Maybe<Array<UserInfoAccount>>;
};

export class Category {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  enterpriseCategory?: Maybe<Array<EnterpriseCategory>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  offerCategory?: Maybe<Array<OfferCategory>>;
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class CategoryPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Category>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class ChangePasswordInput {
  password: Scalars['String'];
  passwordOld: Scalars['String'];
};

export class CreateCategoryInput {
  name: Scalars['String'];
};

export class CreateCompanyAddressInput {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  complement?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export class CreateEnterpriseInput {
  categories?: InputMaybe<Array<EnterpriseCategoryInput>>;
  cnpj: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export class CreateInfoAdditionalInput {
  accountPurpose?: InputMaybe<Scalars['String']>;
  estimatedValue?: InputMaybe<Scalars['String']>;
  fundsSource?: InputMaybe<Scalars['String']>;
  transactionFrequency?: InputMaybe<Scalars['String']>;
};

export class CreateOfferDocumentsInput {
  offerId: Scalars['String'];
  title: Scalars['String'];
};

export class CreateOfferInput {
  categories?: InputMaybe<Array<OfferCategoryInput>>;
  curriculum: Scalars['String'];
  enterpriseId: Scalars['String'];
  goals: Scalars['String'];
  market: Scalars['String'];
  name: Scalars['String'];
  objective: Scalars['String'];
  urlVideo?: InputMaybe<Scalars['String']>;
};

export class CreateOfferMaterialsInput {
  description: Scalars['String'];
  offerId: Scalars['String'];
  title: Scalars['String'];
};

export class CreateOfferTeamInput {
  name: Scalars['String'];
  offerId: Scalars['String'];
  role: Scalars['String'];
};

export class CreatePostCommentInput {
  comment: Scalars['String'];
  postId: Scalars['String'];
};

export class CreatePostInput {
  enterpriseId: Scalars['String'];
  isActive: Scalars['Boolean'];
  text: Scalars['String'];
};

export class CreateTermOfUseInput {
  term: Scalars['String'];
  type: Scalars['String'];
};

export class CreateUserAddressInput {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  complement?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export class CreateUserInfoAccountInput {
  accountDigit?: InputMaybe<Scalars['String']>;
  accountHolderCpf?: InputMaybe<Scalars['String']>;
  accountHolderName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  accountType?: InputMaybe<Scalars['String']>;
  agency?: InputMaybe<Scalars['String']>;
  bankId?: InputMaybe<Scalars['String']>;
};

export class CreateUserInfoProfessionalInput {
  companyName?: InputMaybe<Scalars['String']>;
  jobRole?: InputMaybe<Scalars['String']>;
  payRange?: InputMaybe<Scalars['String']>;
  workRegimeId?: InputMaybe<Scalars['String']>;
};

export class CreateUserInput {
  birthday: Scalars['DateTime'];
  companyAddress?: InputMaybe<CreateCompanyAddressInput>;
  cpf: Scalars['String'];
  documentExpirationDate?: InputMaybe<Scalars['DateTime']>;
  documentIssueDate?: InputMaybe<Scalars['DateTime']>;
  documentNumber?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nationality: Scalars['String'];
  nickname: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  userAddress?: InputMaybe<CreateUserAddressInput>;
  userInfoAccount?: InputMaybe<CreateUserInfoAccountInput>;
  userInfoAdditional?: InputMaybe<CreateInfoAdditionalInput>;
  userInfoProfessional?: InputMaybe<CreateUserInfoProfessionalInput>;
};

export class DefaultMessage {
  message: Scalars['String'];
  status: Scalars['Int'];
};

export class Enterprise {
  cnpj: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  enterpriseCategory?: Maybe<Array<EnterpriseCategory>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  offer?: Maybe<Array<Offer>>;
  phone: Scalars['String'];
  post?: Maybe<Array<Post>>;
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class EnterpriseCategory {
  category: Category;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  enterprise: Enterprise;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export class EnterpriseCategoryInput {
  categoryId: Scalars['String'];
};

export class EnterprisePage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Enterprise>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class EnumType {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export class Mutation {
  changePassword: DefaultMessage;
  createCategory: Category;
  createEnterprise: Enterprise;
  createOffer: Offer;
  /** Create a new Offer Document */
  createOfferDocuments: OfferDocuments;
  createOfferMaterials: OfferMaterials;
  createOfferTeam: OfferTeam;
  createPost: Post;
  /** Create user comment in post */
  createPostComment: DefaultMessage;
  /** Create user like in post */
  createPostLike: DefaultMessage;
  /** Create a new Term Of Use */
  createTermOfUse: DefaultMessage;
  /** Create new User */
  createUser: User;
  /** Remove Category By Id */
  deleteCategory: DefaultMessage;
  /** Remove Enterprise By Id In Admin */
  deleteEnterprise: DefaultMessage;
  /** Remove Offer By Id */
  deleteOffer: DefaultMessage;
  /** Remove Offer Documents By Id */
  deleteOfferDocuments: DefaultMessage;
  /** Remove Offer Materials By Id */
  deleteOfferMaterials: DefaultMessage;
  /** Remove Offer Team By Id */
  deleteOfferTeam: DefaultMessage;
  /** Remove Post By Id */
  deletePost: DefaultMessage;
  /** Remove Post Media Item By Id In Admin */
  deletePostMediaItem: DefaultMessage;
  /** Delete a Term Of Use by Id */
  deleteTermOfUse: DefaultMessage;
  /** Delete a User */
  deleteUser: DefaultMessage;
  forgotPassword: DefaultMessage;
  login: AuthType;
  /** Resend Email Code */
  resendEmailCode: DefaultMessage;
  resetPassword: DefaultMessage;
  /** Update Category */
  updateCategory: Category;
  /** Update Enterprise In Admin */
  updateEnterprise: Enterprise;
  /** Update Offer */
  updateOffer: Offer;
  /** Update Offer Documents */
  updateOfferDocuments: OfferDocuments;
  /** Update Offer Materials */
  updateOfferMaterials: OfferMaterials;
  /** Update Offer Team */
  updateOfferTeam: OfferTeam;
  /** Update Post */
  updatePost: Post;
  /** Update status comment In Admin */
  updateStatusComment: DefaultMessage;
  /** Update a Term Of Use */
  updateTermOfUse: DefaultMessage;
  /** Update a User */
  updateUser: DefaultMessage;
  /** Verify Email by Code */
  verifyEmail: DefaultMessage;
};


export class MutationChangePasswordArgs {
  data: ChangePasswordInput;
};


export class MutationCreateCategoryArgs {
  data: CreateCategoryInput;
};


export class MutationCreateEnterpriseArgs {
  data: CreateEnterpriseInput;
};


export class MutationCreateOfferArgs {
  data: CreateOfferInput;
};


export class MutationCreateOfferDocumentsArgs {
  data: CreateOfferDocumentsInput;
};


export class MutationCreateOfferMaterialsArgs {
  data: CreateOfferMaterialsInput;
};


export class MutationCreateOfferTeamArgs {
  data: CreateOfferTeamInput;
};


export class MutationCreatePostArgs {
  data: CreatePostInput;
};


export class MutationCreatePostCommentArgs {
  data: CreatePostCommentInput;
};


export class MutationCreatePostLikeArgs {
  postId: Scalars['String'];
};


export class MutationCreateTermOfUseArgs {
  data: CreateTermOfUseInput;
};


export class MutationCreateUserArgs {
  data: CreateUserInput;
};


export class MutationDeleteCategoryArgs {
  id: Scalars['String'];
};


export class MutationDeleteEnterpriseArgs {
  id: Scalars['String'];
};


export class MutationDeleteOfferArgs {
  id: Scalars['String'];
};


export class MutationDeleteOfferDocumentsArgs {
  id: Scalars['String'];
};


export class MutationDeleteOfferMaterialsArgs {
  id: Scalars['String'];
};


export class MutationDeleteOfferTeamArgs {
  id: Scalars['String'];
};


export class MutationDeletePostArgs {
  id: Scalars['String'];
};


export class MutationDeletePostMediaItemArgs {
  id: Scalars['String'];
};


export class MutationDeleteTermOfUseArgs {
  id: Scalars['String'];
};


export class MutationForgotPasswordArgs {
  email: Scalars['String'];
};


export class MutationLoginArgs {
  data: AuthInput;
};


export class MutationResendEmailCodeArgs {
  email: Scalars['String'];
};


export class MutationResetPasswordArgs {
  data: ResetPasswordInput;
};


export class MutationUpdateCategoryArgs {
  data: UpdateCategoryInput;
};


export class MutationUpdateEnterpriseArgs {
  data: UpdateEnterpriseInput;
};


export class MutationUpdateOfferArgs {
  data: UpdateOfferInput;
};


export class MutationUpdateOfferDocumentsArgs {
  data: UpdateOfferDocumentsInput;
};


export class MutationUpdateOfferMaterialsArgs {
  data: UpdateOfferMaterialsInput;
};


export class MutationUpdateOfferTeamArgs {
  data: UpdateOfferTeamInput;
};


export class MutationUpdatePostArgs {
  data: UpdatePostInput;
};


export class MutationUpdateStatusCommentArgs {
  data: UpdateStatusCommentInput;
};


export class MutationUpdateTermOfUseArgs {
  data: UpdateTermOfUseInput;
};


export class MutationUpdateUserArgs {
  data: UpdateUserDtoInput;
};


export class MutationVerifyEmailArgs {
  code: Scalars['String'];
};

export class Offer {
  createdAt: Scalars['DateTime'];
  curriculum: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  enterprise: Enterprise;
  goals: Scalars['String'];
  id: Scalars['ID'];
  market: Scalars['String'];
  name: Scalars['String'];
  objective: Scalars['String'];
  offerCategory?: Maybe<Array<OfferCategory>>;
  offerDocuments?: Maybe<Array<OfferDocuments>>;
  offerMaterials?: Maybe<Array<OfferMaterials>>;
  offerTeam?: Maybe<Array<OfferTeam>>;
  updatedAt: Scalars['DateTime'];
  urlBannerImage?: Maybe<Scalars['String']>;
  urlVideo?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class OfferCategory {
  category: Category;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offer: Offer;
  updatedAt: Scalars['DateTime'];
};

export class OfferCategoryInput {
  categoryId: Scalars['String'];
};

export class OfferDocumentMonth {
  month: Scalars['Float'];
  monthName: Scalars['String'];
  results: Array<OfferDto>;
};

export class OfferDocuments {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  extensionMedia?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  offer: Offer;
  originalName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlMedia?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class OfferDocumentsByDateDto {
  offerDocumentMonths: Array<OfferDocumentMonth>;
  year: Scalars['Float'];
};

export class OfferDocumentsDto {
  createdAt: Scalars['DateTime'];
  extensionMedia: Scalars['String'];
  id: Scalars['String'];
  originalName: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlMedia: Scalars['String'];
};

export class OfferDto {
  offerDocuments: Array<OfferDocumentsDto>;
  offerName: Scalars['String'];
};

export class OfferMaterials {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  extensionMedia?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  offer: Offer;
  originalName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlMedia?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class OfferPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Offer>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class OfferTeam {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  offer: Offer;
  role: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlPhotoImage?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class Post {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  enterprise: Enterprise;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  postComment?: Maybe<Array<PostComment>>;
  postLike?: Maybe<Array<PostLike>>;
  postMedia?: Maybe<Array<PostMedia>>;
  qtdComment?: Maybe<Scalars['Float']>;
  qtdCommentApproved?: Maybe<Scalars['Float']>;
  qtdCommentPending?: Maybe<Scalars['Float']>;
  qtdCommentReproved?: Maybe<Scalars['Float']>;
  qtdLike?: Maybe<Scalars['Float']>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userLiked?: Maybe<Scalars['Boolean']>;
  userUpdated?: Maybe<User>;
};

export class PostComment {
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  post?: Maybe<Post>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedStatusAt: Scalars['DateTime'];
  user: User;
  userUpdatedStatus?: Maybe<User>;
};

export class PostCommentPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<PostComment>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class PostLike {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  post?: Maybe<Post>;
  user: User;
};

export class PostMedia {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  extensionImage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  originalImageName?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  urlVideo?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
  videoOf?: Maybe<Scalars['String']>;
};

export class PostPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Post>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Query {
  /** Return a Bank By Id */
  bank: Bank;
  /** Return All Banks */
  banks: Array<Bank>;
  /** Return Category By ID */
  categoryById: Category;
  /** Return Comment By ID In Admin */
  commentById: PostComment;
  /** Return User Logged */
  currentUser: User;
  /** Return Enterprise By ID In Admin */
  enterpriseById: Enterprise;
  /** Return All By Type */
  enumByType: Array<EnumType>;
  /** Return Enum Type by Id */
  enumTypeById: EnumType;
  /** Return All Enum Types */
  enumTypes: Array<EnumType>;
  /** Return All Category List */
  listAllCategory: Array<Category>;
  /** Return All Enterprise List In Admin */
  listAllEnterprise: Array<Enterprise>;
  /** Return all Offer Documents by Year and Month */
  listAllOfferDocumentsByDate: Array<OfferDocumentsByDateDto>;
  /** Return Offer By ID */
  offerById: Offer;
  /** Return Offer Document By ID */
  offerDocumentsById: OfferDocuments;
  /** Return Offer Materials By ID */
  offerMaterialsById: OfferMaterials;
  /** Return Offer Team By ID */
  offerTeamById: OfferTeam;
  /** Return Post By ID */
  postById: Post;
  /** Return Post Media Item By ID In Admin */
  postMediaItemById: PostMedia;
  /** Return All Category List Paged */
  searchAllCategory: CategoryPage;
  /** Return All Comments paged In Admin */
  searchAllComments: PostCommentPage;
  /** Return All Enterprise List Paged In Admin */
  searchAllEnterprise: EnterprisePage;
  /** Return All Offer List Paged */
  searchAllOffer: OfferPage;
  /** Return All Post paged In Admin */
  searchAllPost: PostPage;
  /** Return All Enterprise In WEB */
  showAllEnterprise: Array<Enterprise>;
  /** Return a Term Of Use by Id */
  termOfUse: TermOfUse;
  /** Return a Term Of Use by Type */
  termOfUseByType: Array<TermOfUse>;
  /** Return All Terms of Use */
  termsOfUse: Array<TermOfUse>;
  /** Return User By Id */
  userById: User;
  /** Return All users paged */
  users: UserPage;
  /** Return All users by profile */
  usersByProfile: Array<User>;
  workRegime: WorkRegime;
  /** Return All Work Regimes */
  workRegimes: Array<WorkRegime>;
};


export class QueryBankArgs {
  id: Scalars['String'];
};


export class QueryCategoryByIdArgs {
  id: Scalars['String'];
};


export class QueryCommentByIdArgs {
  id: Scalars['String'];
};


export class QueryEnterpriseByIdArgs {
  id: Scalars['String'];
};


export class QueryEnumByTypeArgs {
  type: Scalars['String'];
};


export class QueryEnumTypeByIdArgs {
  id: Scalars['String'];
};


export class QueryListAllOfferDocumentsByDateArgs {
  offerId: Scalars['String'];
};


export class QueryOfferByIdArgs {
  id: Scalars['String'];
};


export class QueryOfferDocumentsByIdArgs {
  id: Scalars['String'];
};


export class QueryOfferMaterialsByIdArgs {
  id: Scalars['String'];
};


export class QueryOfferTeamByIdArgs {
  id: Scalars['String'];
};


export class QueryPostByIdArgs {
  id: Scalars['String'];
};


export class QueryPostMediaItemByIdArgs {
  id: Scalars['String'];
};


export class QuerySearchAllCategoryArgs {
  data: SearchCategoryInput;
};


export class QuerySearchAllCommentsArgs {
  data: SearchPostCommentsInput;
};


export class QuerySearchAllEnterpriseArgs {
  data: SearchEnterpriseInput;
};


export class QuerySearchAllOfferArgs {
  data: SearchOfferInput;
};


export class QuerySearchAllPostArgs {
  data: SearchPostInput;
};


export class QueryTermOfUseArgs {
  id: Scalars['String'];
};


export class QueryTermOfUseByTypeArgs {
  type: Scalars['String'];
};


export class QueryUserByIdArgs {
  id: Scalars['String'];
};


export class QueryUsersArgs {
  data: SearchUserInput;
};


export class QueryUsersByProfileArgs {
  profile: Scalars['String'];
};


export class QueryWorkRegimeArgs {
  id: Scalars['String'];
};

export class ResetPasswordInput {
  code: Scalars['String'];
  newPassword: Scalars['String'];
};

export class SearchCategoryInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchEnterpriseInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchOfferInput {
  end?: InputMaybe<Scalars['DateTime']>;
  enterpriseId?: InputMaybe<Array<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchPostCommentsInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<Scalars['String']>>;
};

export class SearchPostInput {
  end?: InputMaybe<Scalars['DateTime']>;
  enterpriseId?: InputMaybe<Array<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchUserInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class TermOfUse {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  term: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class UpdateCategoryInput {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export class UpdateCompanyAddressInput {
  address: Scalars['String'];
  city: Scalars['String'];
  complement: Scalars['String'];
  district: Scalars['String'];
  number: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export class UpdateEnterpriseInput {
  categories?: InputMaybe<Array<EnterpriseCategoryInput>>;
  cnpj?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export class UpdateOfferDocumentsInput {
  id: Scalars['String'];
  offerId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateOfferInput {
  categories?: InputMaybe<Array<OfferCategoryInput>>;
  curriculum?: InputMaybe<Scalars['String']>;
  enterpriseId?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  market?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  objective?: InputMaybe<Scalars['String']>;
  urlVideo?: InputMaybe<Scalars['String']>;
};

export class UpdateOfferMaterialsInput {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  offerId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateOfferTeamInput {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  offerId?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export class UpdatePostInput {
  enterpriseId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};

export class UpdateStatusCommentInput {
  commentId: Scalars['String'];
  /** ENUM approved | reproved */
  status: Scalars['String'];
};

export class UpdateTermOfUseInput {
  id: Scalars['String'];
  term: Scalars['String'];
  type: Scalars['String'];
};

export class UpdateUserAddressInput {
  address: Scalars['String'];
  city: Scalars['String'];
  complement: Scalars['String'];
  district: Scalars['String'];
  number: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export class UpdateUserDtoInput {
  companyAddress: UpdateCompanyAddressInput;
  id: Scalars['String'];
  user: UpdateUserInput;
  userAddress: UpdateUserAddressInput;
  userInfoAccount: UpdateUserInfoAccountInput;
  userInfoProfessional: UpdateUserInfoProfessionalInput;
};

export class UpdateUserInfoAccountInput {
  accountDigit: Scalars['String'];
  accountHolderCpf: Scalars['String'];
  accountHolderName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountType: Scalars['String'];
  agency: Scalars['String'];
  bankId: Scalars['String'];
};

export class UpdateUserInfoProfessionalInput {
  companyName: Scalars['String'];
  jobRole: Scalars['String'];
  payRange: Scalars['String'];
  workRegimeId: Scalars['String'];
};

export class UpdateUserInput {
  birthday: Scalars['DateTime'];
  cpf: Scalars['String'];
  email: Scalars['String'];
  gender: Scalars['String'];
  name: Scalars['String'];
  nationality: Scalars['String'];
  nickname: Scalars['String'];
  phone: Scalars['String'];
};

export class User {
  access?: Maybe<Array<UserAccess>>;
  address?: Maybe<Array<UserAddress>>;
  birthday: Scalars['DateTime'];
  cpf: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  documentExpirationDate?: Maybe<Scalars['DateTime']>;
  documentIssueDate?: Maybe<Scalars['DateTime']>;
  documentNumber: Scalars['String'];
  documentPhotoBackUrl?: Maybe<Scalars['String']>;
  documentPhotoFrontUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailCodeExpirationDate?: Maybe<Scalars['DateTime']>;
  emailVerificationCode?: Maybe<Scalars['String']>;
  emailVerifiedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  infoAccount?: Maybe<Array<UserInfoAccount>>;
  infoAdditional?: Maybe<Array<UserInfoAdditional>>;
  infoProfessional?: Maybe<Array<UserInfoProfessional>>;
  isAcceptedPolicies: Scalars['Boolean'];
  isAcceptedTerms: Scalars['Boolean'];
  isEmailVerified: Scalars['Boolean'];
  name: Scalars['String'];
  nationality: Scalars['String'];
  nickname: Scalars['String'];
  passwordChangedAt: Scalars['DateTime'];
  phone: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  postComment?: Maybe<Array<PostComment>>;
  postLike?: Maybe<Array<PostLike>>;
  profile: Scalars['String'];
  resetPasswordExpiration?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  verificationPhotoUrl?: Maybe<Scalars['String']>;
};

export class UserAccess {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ip: Scalars['String'];
  user: User;
};

export class UserAddress {
  address: Scalars['String'];
  bankStatementUrl?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  complement: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  district: Scalars['String'];
  id: Scalars['String'];
  number: Scalars['String'];
  publicServiceAccountUrl?: Maybe<Scalars['String']>;
  rentalContractUrl?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  taxReturnUrl?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  zipCode: Scalars['String'];
};

export class UserInfoAccount {
  accountDigit: Scalars['String'];
  accountHolderCpf: Scalars['String'];
  accountHolderName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountType: Scalars['String'];
  agency: Scalars['String'];
  bank?: Maybe<Bank>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  incomeDeclarationUrl: Scalars['String'];
  incomeEvidenceUrl: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class UserInfoAdditional {
  accountPurpose: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  estimatedValue: Scalars['String'];
  fundsSource: Scalars['String'];
  id: Scalars['ID'];
  transactionFrequency: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class UserInfoProfessional {
  companyName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  jobRole: Scalars['String'];
  payRange: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  workRegime: WorkRegime;
};

export class UserPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<User>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class WorkRegime {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  regime: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userInfoProfessional?: Maybe<Array<UserInfoProfessional>>;
};

export type AuthTypeFragment = { token: string, user: { birthday: any, createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, phone: string, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null } };

export type UserFragment = { birthday: any, createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, phone: string, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null };

export type DefaultMessageFragment = { message: string, status: number };

export type EnterprisePageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, cnpj: string, name: string, email: string, phone: string, urlImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, enterpriseCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any } }> | null }> | null };

export type EnterpriseFragment = { id: string, cnpj: string, name: string, email: string, phone: string, urlImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, enterpriseCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any } }> | null };

export type EnterpriseCategoryFragment = { id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any } };

export type CategoryFragment = { id: string, name: string, createdAt: any };

export type CategoryPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, name: string, createdAt: any }> | null };

export type OfferTeamFragment = { id: string, name: string, role: string };

export type OfferFragment = { createdAt: any, curriculum: string, deletedAt?: any | null, goals: string, id: string, market: string, name: string, objective: string, updatedAt: any, urlBannerImage?: string | null, urlVideo?: string | null, enterprise: { id: string, name: string }, offerTeam?: Array<{ id: string, name: string, role: string }> | null };

export type OfferPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ createdAt: any, curriculum: string, deletedAt?: any | null, goals: string, id: string, market: string, name: string, objective: string, updatedAt: any, urlBannerImage?: string | null, urlVideo?: string | null, enterprise: { id: string, name: string }, offerTeam?: Array<{ id: string, name: string, role: string }> | null }> | null };

export type CreateCategoryMutationVariables = Exact<{
  data: CreateCategoryInput;
}>;


export type CreateCategoryMutation = { createCategory: { id: string, name: string, createdAt: any } };

export type UpdateCategoryMutationVariables = Exact<{
  data: UpdateCategoryInput;
}>;


export type UpdateCategoryMutation = { updateCategory: { id: string, name: string, createdAt: any } };

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCategoryMutation = { deleteCategory: { message: string, status: number } };

export type SearchAllCategoryQueryVariables = Exact<{
  data: SearchCategoryInput;
}>;


export type SearchAllCategoryQuery = { searchAllCategory: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, name: string, createdAt: any }> | null } };

export type ListAllCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAllCategoryQuery = { listAllCategory: Array<{ id: string, name: string }> };

export type ListAllEnterpriseQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAllEnterpriseQuery = { listAllEnterprise: Array<{ id: string, name: string }> };

export type CreateEnterpriseMutationVariables = Exact<{
  data: CreateEnterpriseInput;
}>;


export type CreateEnterpriseMutation = { createEnterprise: { id: string, cnpj: string, name: string, email: string, phone: string, urlImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, enterpriseCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any } }> | null } };

export type UpdateEnterpriseMutationVariables = Exact<{
  data: UpdateEnterpriseInput;
}>;


export type UpdateEnterpriseMutation = { updateEnterprise: { id: string, cnpj: string, name: string, email: string, phone: string, urlImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, enterpriseCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any } }> | null } };

export type DeleteEnterpriseMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteEnterpriseMutation = { deleteEnterprise: { message: string, status: number } };

export type SearchAllEnterpriseQueryVariables = Exact<{
  data: SearchEnterpriseInput;
}>;


export type SearchAllEnterpriseQuery = { searchAllEnterprise: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, cnpj: string, name: string, email: string, phone: string, urlImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, enterpriseCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any } }> | null }> | null } };

export type CreateOfferMutationVariables = Exact<{
  data: CreateOfferInput;
}>;


export type CreateOfferMutation = { createOffer: { createdAt: any, curriculum: string, deletedAt?: any | null, goals: string, id: string, market: string, name: string, objective: string, updatedAt: any, urlBannerImage?: string | null, urlVideo?: string | null, enterprise: { id: string, name: string }, offerTeam?: Array<{ id: string, name: string, role: string }> | null } };

export type UpdateOfferMutationVariables = Exact<{
  data: UpdateOfferInput;
}>;


export type UpdateOfferMutation = { updateOffer: { createdAt: any, curriculum: string, deletedAt?: any | null, goals: string, id: string, market: string, name: string, objective: string, updatedAt: any, urlBannerImage?: string | null, urlVideo?: string | null, enterprise: { id: string, name: string }, offerTeam?: Array<{ id: string, name: string, role: string }> | null } };

export type DeleteOfferMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOfferMutation = { deleteOffer: { message: string, status: number } };

export type SearchAllOfferQueryVariables = Exact<{
  data: SearchOfferInput;
}>;


export type SearchAllOfferQuery = { searchAllOffer: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ createdAt: any, curriculum: string, deletedAt?: any | null, goals: string, id: string, market: string, name: string, objective: string, updatedAt: any, urlBannerImage?: string | null, urlVideo?: string | null, enterprise: { id: string, name: string }, offerTeam?: Array<{ id: string, name: string, role: string }> | null }> | null } };

export type LoginMutationVariables = Exact<{
  data: AuthInput;
}>;


export type LoginMutation = { login: { token: string, user: { birthday: any, createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, phone: string, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null } } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUser: { birthday: any, createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, phone: string, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null } };

export type WorkRegimesQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkRegimesQuery = { workRegimes: Array<{ id: string, regime: string }> };

export type BanksQueryVariables = Exact<{ [key: string]: never; }>;


export type BanksQuery = { banks: Array<{ id: string, name: string }> };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { forgotPassword: { message: string, status: number } };

export type VerifyEmailMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type VerifyEmailMutation = { verifyEmail: { message: string, status: number } };

export type ResendEmailCodeMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResendEmailCodeMutation = { resendEmailCode: { message: string, status: number } };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { resetPassword: { message: string, status: number } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { createUser: { birthday: any, createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, phone: string, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null } };

export type UsersQueryVariables = Exact<{
  data: SearchUserInput;
}>;


export type UsersQuery = { users: { currentPage: number, nextPage: boolean, pageSize: number, previousPage: boolean, totalCount: number, totalPage: number, results?: Array<{ birthday: any, createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, phone: string, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null }> | null } };

export const UserFragmentDoc = gql`
    fragment User on User {
  birthday
  createdAt
  deletedAt
  email
  id
  name
  phone
  photoUrl
  profile
  resetPasswordExpiration
}
    `;
export const AuthTypeFragmentDoc = gql`
    fragment AuthType on AuthType {
  token
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  message
  status
}
    `;
export const CategoryFragmentDoc = gql`
    fragment Category on Category {
  id
  name
  createdAt
}
    `;
export const EnterpriseCategoryFragmentDoc = gql`
    fragment EnterpriseCategory on EnterpriseCategory {
  id
  createdAt
  updatedAt
  deletedAt
  category {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;
export const EnterpriseFragmentDoc = gql`
    fragment Enterprise on Enterprise {
  id
  cnpj
  name
  email
  phone
  urlImage
  createdAt
  updatedAt
  deletedAt
  enterpriseCategory {
    ...EnterpriseCategory
  }
}
    ${EnterpriseCategoryFragmentDoc}`;
export const EnterprisePageFragmentDoc = gql`
    fragment EnterprisePage on EnterprisePage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...Enterprise
  }
}
    ${EnterpriseFragmentDoc}`;
export const CategoryPageFragmentDoc = gql`
    fragment CategoryPage on CategoryPage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;
export const OfferTeamFragmentDoc = gql`
    fragment OfferTeam on OfferTeam {
  id
  name
  role
}
    `;
export const OfferFragmentDoc = gql`
    fragment Offer on Offer {
  createdAt
  curriculum
  deletedAt
  enterprise {
    id
    name
  }
  goals
  id
  market
  name
  objective
  offerTeam {
    ...OfferTeam
  }
  updatedAt
  urlBannerImage
  urlVideo
}
    ${OfferTeamFragmentDoc}`;
export const OfferPageFragmentDoc = gql`
    fragment OfferPage on OfferPage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...Offer
  }
}
    ${OfferFragmentDoc}`;
export const CreateCategoryDocument = gql`
    mutation createCategory($data: CreateCategoryInput!) {
  createCategory(data: $data) {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCategoryGQL extends Apollo.Mutation<CreateCategoryMutation, CreateCategoryMutationVariables> {
    document = CreateCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCategoryDocument = gql`
    mutation updateCategory($data: UpdateCategoryInput!) {
  updateCategory(data: $data) {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCategoryGQL extends Apollo.Mutation<UpdateCategoryMutation, UpdateCategoryMutationVariables> {
    document = UpdateCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($id: String!) {
  deleteCategory(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCategoryGQL extends Apollo.Mutation<DeleteCategoryMutation, DeleteCategoryMutationVariables> {
    document = DeleteCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchAllCategoryDocument = gql`
    query searchAllCategory($data: SearchCategoryInput!) {
  searchAllCategory(data: $data) {
    ...CategoryPage
  }
}
    ${CategoryPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchAllCategoryGQL extends Apollo.Query<SearchAllCategoryQuery, SearchAllCategoryQueryVariables> {
    document = SearchAllCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListAllCategoryDocument = gql`
    query listAllCategory {
  listAllCategory {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListAllCategoryGQL extends Apollo.Query<ListAllCategoryQuery, ListAllCategoryQueryVariables> {
    document = ListAllCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListAllEnterpriseDocument = gql`
    query listAllEnterprise {
  listAllEnterprise {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListAllEnterpriseGQL extends Apollo.Query<ListAllEnterpriseQuery, ListAllEnterpriseQueryVariables> {
    document = ListAllEnterpriseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEnterpriseDocument = gql`
    mutation createEnterprise($data: CreateEnterpriseInput!) {
  createEnterprise(data: $data) {
    ...Enterprise
  }
}
    ${EnterpriseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEnterpriseGQL extends Apollo.Mutation<CreateEnterpriseMutation, CreateEnterpriseMutationVariables> {
    document = CreateEnterpriseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEnterpriseDocument = gql`
    mutation updateEnterprise($data: UpdateEnterpriseInput!) {
  updateEnterprise(data: $data) {
    ...Enterprise
  }
}
    ${EnterpriseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEnterpriseGQL extends Apollo.Mutation<UpdateEnterpriseMutation, UpdateEnterpriseMutationVariables> {
    document = UpdateEnterpriseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEnterpriseDocument = gql`
    mutation deleteEnterprise($id: String!) {
  deleteEnterprise(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEnterpriseGQL extends Apollo.Mutation<DeleteEnterpriseMutation, DeleteEnterpriseMutationVariables> {
    document = DeleteEnterpriseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchAllEnterpriseDocument = gql`
    query searchAllEnterprise($data: SearchEnterpriseInput!) {
  searchAllEnterprise(data: $data) {
    ...EnterprisePage
  }
}
    ${EnterprisePageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchAllEnterpriseGQL extends Apollo.Query<SearchAllEnterpriseQuery, SearchAllEnterpriseQueryVariables> {
    document = SearchAllEnterpriseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOfferDocument = gql`
    mutation createOffer($data: CreateOfferInput!) {
  createOffer(data: $data) {
    ...Offer
  }
}
    ${OfferFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOfferGQL extends Apollo.Mutation<CreateOfferMutation, CreateOfferMutationVariables> {
    document = CreateOfferDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOfferDocument = gql`
    mutation updateOffer($data: UpdateOfferInput!) {
  updateOffer(data: $data) {
    ...Offer
  }
}
    ${OfferFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOfferGQL extends Apollo.Mutation<UpdateOfferMutation, UpdateOfferMutationVariables> {
    document = UpdateOfferDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOfferDocument = gql`
    mutation deleteOffer($id: String!) {
  deleteOffer(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOfferGQL extends Apollo.Mutation<DeleteOfferMutation, DeleteOfferMutationVariables> {
    document = DeleteOfferDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchAllOfferDocument = gql`
    query searchAllOffer($data: SearchOfferInput!) {
  searchAllOffer(data: $data) {
    ...OfferPage
  }
}
    ${OfferPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchAllOfferGQL extends Apollo.Query<SearchAllOfferQuery, SearchAllOfferQueryVariables> {
    document = SearchAllOfferDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation login($data: AuthInput!) {
  login(data: $data) {
    ...AuthType
  }
}
    ${AuthTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WorkRegimesDocument = gql`
    query workRegimes {
  workRegimes {
    id
    regime
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WorkRegimesGQL extends Apollo.Query<WorkRegimesQuery, WorkRegimesQueryVariables> {
    document = WorkRegimesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BanksDocument = gql`
    query banks {
  banks {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BanksGQL extends Apollo.Query<BanksQuery, BanksQueryVariables> {
    document = BanksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    document = ForgotPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VerifyEmailDocument = gql`
    mutation verifyEmail($code: String!) {
  verifyEmail(code: $code) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VerifyEmailGQL extends Apollo.Mutation<VerifyEmailMutation, VerifyEmailMutationVariables> {
    document = VerifyEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResendEmailCodeDocument = gql`
    mutation resendEmailCode($email: String!) {
  resendEmailCode(email: $email) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResendEmailCodeGQL extends Apollo.Mutation<ResendEmailCodeMutation, ResendEmailCodeMutationVariables> {
    document = ResendEmailCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordGQL extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
    document = ResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(data: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query users($data: SearchUserInput!) {
  users(data: $data) {
    currentPage
    nextPage
    pageSize
    previousPage
    totalCount
    totalPage
    results {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }